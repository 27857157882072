import { general_api_response_body, OpenAPI } from '@/src/lib/api/v3/client';
import { request } from '@/src/lib/api/v3/client/core/request';

export class MiscellaneousService {
  public static amountRaisedInInterval = async (
    start: string,
    end: string,
  ): Promise<
    general_api_response_body & {
      result: { total_funded_during_interval: number };
    }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/donationStream/donationsDuringInterval',
      body: {
        start,
        end,
      },
    });
  };
  public static getOptInInfo = async (): Promise<
    general_api_response_body & {
      result: boolean;
    }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/isItOptIn',
    });
  };
}

export const getCountries = async () => {
  const response = await fetch(
    `https://launchgood.s3.amazonaws.com/static/rebuild_assets/countries.json`,
  );
  const data = await response.json();
  return data;
};
